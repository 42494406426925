<template>
    <v-dialog v-model="show" width="500" persistent>
        <v-form ref="form" @submit.prevent="save">
            <v-card :loading="loading" :disabled="loading">
                <v-card-title>Редактор</v-card-title>
                <v-card-text>
                    <v-text-field :rules="req" v-model="name" label="Наименование" />
                    <v-text-field :rules="req" v-model="link" label="Ссылка" />
                    <v-file-input 
                        label="Новая иконка" 
                        :rules="id == 0 ? req : []" 
                        v-model="file"
                        accept="image/jpeg, image/png"
                        clearable
                    />
                    <v-img v-if="preview" :src="preview" width="50" height="50" />
                    <v-checkbox label="Скрыт" v-model="hidden" />
                </v-card-text>
                <v-card-actions>
                    <v-spacer />
                    <v-btn color="error" text @click="close">Отмена</v-btn>
                    <v-btn color="primary" text type="submit">Сохранить</v-btn>
                </v-card-actions>
            </v-card>
        </v-form>
        <FileUploader ref="uploader" />
    </v-dialog>
</template> 

<script>
import FileUploader from '@c/FileUploader.vue';

export default {
    components: {
        FileUploader,
    },
    data: () => ({
        show: false,
        loading: false,
        name: '',
        link: '',
        file: null,
        hidden: false,
        preview: null,
        id: 0,
    }),
    methods: {
        async open(id) {
            this.name = '';
            this.link = '';
            this.hidden = false;
            this.file = null;
            this.preview = null;
            this.id = id;
            this.show = true;

            if (id) {
                this.loading = true;
                const { one } = await this.$api(`/admin/resources/${id}`);
                
                this.name = one.name;
                this.link = one.link;
                this.hidden = one.hidden;
                this.preview = `${process.env.VUE_APP_API}/uploads/resource/${id}.png?t=${(new Date()).getTime()}`;

                this.loading = false;
            }
        },
        close() {
            this.show = false;
        },
        async save() {
            if (!this.$refs.form.validate()) {
                return;
            }

            let file = null;

            if (this.file) {
                this.loading = true;
                const files = await this.$refs.uploader.upload([this.file]);
                file = files[0].tmp;
                this.loading = false;
            }

            let url = '/admin/resources';
            if (this.id) {
                url += `/${this.id}`;
            }

            this.loading = true;
            const { one } = await this.$api(url, {
                method: this.id ? 'PATCH' : 'POST',
                body: {
                    name: this.name,
                    link: this.link,
                    hidden: this.hidden,
                    file,
                },
            });
            this.$emit('saved', one);
            this.loading = false;
            this.close();
        },
    },
    computed: {
        req: () => [
            v => !!v || 'Введите значение'
        ],
    },
};
</script>
