<template>
    <v-container fluid>
        <v-overlay :value="loading">
            <v-progress-circular size="50" indeterminate />
        </v-overlay>
        <v-btn color="primary" @click="edit(0)">Добавить</v-btn>
        <v-simple-table>
            <thead>
                <tr>
                    <th></th>
                    <th>#</th>
                    <th>Наименование</th>
                    <th>Ссылка</th>
                    <th></th>
                </tr>
            </thead>
            <Draggable 
                tag="tbody" 
                v-model="list" 
                handle=".handle"
                @end="saveSort"
            >
                <tr v-for="l in list" :key="l.id" :class="{
                    hidden: l.hidden,
                }">
                    <td class="handle">
                        <v-icon>mdi-sort</v-icon>
                    </td>
                    <td>
                        {{ l.id }}
                    </td>
                    <td>
                        {{ l.name }}
                    </td>
                    <td>
                        {{ l.link }}
                    </td>
                    <td>
                        <v-btn icon color="primary" @click="edit(l.id)">
                            <v-icon>mdi-pencil</v-icon>
                        </v-btn>
                        <v-btn icon color="error" @click="remove(l.id)">
                            <v-icon>mdi-delete</v-icon>
                        </v-btn>
                    </td>
                </tr>
            </Draggable>
        </v-simple-table>
        <Editor ref="editor" @saved="saved" />
    </v-container>
</template> 

<script>
import Editor from '@c/resources/Editor';
import Draggable from "vuedraggable";

export default {
    components: {
        Editor,
        Draggable,
    },
    data: () => ({
        list: [],
        loading: false,
    }),
    async mounted() {
        this.loading = true;
        const { list } = await this.$api('/admin/resources');
        this.list = list;
        this.loading = false;
    },
    methods: {
        edit(id) {
            this.$refs.editor.open(id);
        },
        saved(one) {
            const index = this.list.findIndex(l => l.id === one.id);
            if (index === -1) {
                this.list.unshift(one);
            } else {
                this.list.splice(index, 1, one);
            }
        },
        async remove(id) {
            if (!await this.$confirm('Подтвердите удаление')) {
                return;
            }

            this.loading = true;
            await this.$api(`/admin/resources/${id}`, {
                method: 'DELETE',
            });
            this.loading = false;

            const index = this.list.findIndex(l => l.id === id);
            this.list.splice(index, 1);
        },
        async saveSort() {
            await this.$api('/admin/resources/sort', {
                method: 'PATCH',
                body: {
                    list: this.list.reduce((acc, item, index) => {
                        acc[item.id] = index + 1;

                        return acc;
                    }, {}),
                },
            });
        },
    },
};
</script>

<style scoped lang="scss">
.hidden {
    opacity: .3;
}
</style>